import React from "react";
import { withRouter } from 'react-router-dom';
import ScrollToTop from '../Nav/ScrollToTop'
import { scrollTo } from '../../App';
import { Helmet } from 'react-helmet'
import { Parallax } from 'react-parallax'
import {
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBContainer,
    MDBIcon,
    MDBAnimation
} from "mdbreact";
// import "./style.css";

class ClientCare extends React.Component {
    state = {
        collapsed: false
    };

    handleTogglerClick = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    };

    componentDidMount() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    scrollToGetStarted() {
        var testDiv = document.getElementById("get-started");
        window.scroll({
            top: (testDiv.offsetTop - 25),
            behavior: 'smooth',
        });
    }

    render() {

        return (
            <div id="client-care" style={{ overflow: "hidden" }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Client Care | Rolton MacDuff Lawyers | Property and Business Law</title>
                    {/* <description>We offer a full range of property, business and personal legal services to a diverse client base.</description> */}
                    <link rel="canonical" href="https://www.rolton-macduff.nz/client-care" />
                </Helmet>
                <ScrollToTop />
                <Parallax
                    bgImage={require('../../assets/img/clientcare.png')}
                    bgImageAlt="Christchurch City"
                    // strength={200}
                    className="page-header header-filter clear-filter dark-filter white-text"
                    style={{ borderBottom: '22px solid #01427e' }}
                >
                    <MDBContainer className="text-left mt-5">
                        <div style={{ zIndex: '2', position: "relative" }}>
                            <MDBAnimation reveal type="slideInDown" className="fast">
                                <h1 className="h6-responsive text-uppercase light-grey-text font-quatRegular mt-5">Client Care</h1>
                            </MDBAnimation>
                            <h6 className="h3-responsive mt-3 mb-lg-4 font-quatRegular">Information about working with us.</h6>
                        </div>
                    </MDBContainer>

                    <div style={{ height: '70vh' }} />
                </Parallax>

                <MDBContainer className="my-5 text-center pt-5 pb-5 text-left" id="law-services">
                    <MDBRow className="mb-5 mt-4 text-left secondary-text">
                        <MDBCol md="12" className="mx-auto mb-5">
                            <div className="text-left">
                                <div className="font-quatBold h2-responsive mb-3 secondary-text">Standard Terms of Engagement</div>
                                <div className="h6-responsive text-justify mt-2 mb-2">The basis upon which we provide our legal services to you is subject to our Standard Terms of Engagement which can be found below.
                                </div>
                                <MDBBtn size="sm" color="secondary" href="/Standard Terms of Engagement.pdf" download="Standard Terms of Engagement.pdf" >Download Standard Terms</MDBBtn>
                                <div className="font-quatBold h2-responsive mb-3 mt-5 secondary-text">Information for Clients</div>
                                <div className="h6-responsive text-justify mb-2">
                                    Client care and service information in respect of legal services we provide to you can be found below.</div>
                                <MDBBtn size="sm" color="secondary" href="/Information for Clients.pdf" download="Information for Clients.pdf">Download Client Care Information</MDBBtn>
                            </div>
                            <div className="text-left">
                                <div className="font-quatBold h2-responsive mt-5 mb-3 secondary-text">Anti-Money Laundering and Countering Financing of Terrorism Act 2009</div>
                                <div className="h6-responsive text-justify mt-2 mb-2">Lawyers are subject to the Anti-Money Laundering and Countering Financing of Terrorism Act 2009 (“the Act”) which places obligations on us to obtain and verify information from both prospective and existing clients by way of “Customer Due Diligence”. This information includes verifying clients’ full names, dates of birth and addresses. In some instances, additional information may be required such as a client’s source of wealth and source of funds to meet our obligations under the Act. We will advise what documentation and information is required specifically for each client. Acceptable forms of Identification and Address Verification can be found below.</div>
                                <MDBBtn size="sm" color="secondary" href="/Identification Standard - Quick Reference Guide.pdf" download="Identification Standard - Quick Reference Guide.pdf">Download Guide</MDBBtn>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

            </div >
        );
    }
}

export default withRouter(ClientCare);