import React from "react";
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBCollapse, MDBContainer
} from "mdbreact";
import { withRouter } from 'react-router-dom';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import './style.scss'
// import MobileNavOne from "./mn1";

export const NAV_LINKS = [
    { name: 'Home', path: '/' },
    { name: 'Our Services', path: '/legal-services' },
    { name: 'About Us', path: '/about-us#about' },
    { name: 'Our Team', path: '/about-us#our-team' },
    { name: 'Client Care', path: 'client-care' },
    { name: 'Contact Us', path: 'contact-us' }
]

class Nav extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isTop: true,
            menuOpen: false,
            activeUrl: '/'
        };
    }
    toggleMenu = (e) => {
        if (e)
            e.preventDefault();
        let ele = document.getElementById('mdb-hamburger-menu');
        if (ele) {
            ele.classList.toggle('opened');
            ele.classList.toggle('closed');
        }


        let isOpen = !this.state.menuOpen;
        this.setState({
            menuOpen: isOpen
        });
    }

    updateActiveUrl = (url) => {
        if (window.innerWidth < 992) {
            this.toggleMenu();
        }

        this.setState({
            activeUrl: url
        });

        if (url.includes('#')) {
            let urlSplit = url.split('#');
            if (urlSplit.length > 1) {
                let id = urlSplit[urlSplit.length - 1];
                this.scrollTo(id);
            }
        }
    };

    scrollTo(id) {
        let ele = document.getElementById(id);
        if (ele) {
            let top = ele.offsetTop;
            window.scrollTo({
                top: top,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    render() {
        const links = NAV_LINKS.map((l, i) => {
            return (
                <MDBNavItem className="mb-3" active={l.path === window.location.pathname} onClick={() => this.updateActiveUrl(l.path)}>
                    <NavLink to={l.path} style={{ paddingLeft: "15px", paddingRight: "15px" }}><b className="secondary-text mr-lg-2">{l.name}</b></NavLink>
                </MDBNavItem>
            )
        });

        return (
            <>
                {/* <MobileNavOne links={NAV_LINKS} updateActiveUrl={this.updateActiveUrl} /> */}
                <MDBNavbar
                    id="nav"
                    color="primary-color"
                    light
                    expand="lg"
                    fixed="top"
                    scrolling
                    className="nav-border"
                >
                    <MDBContainer>
                        <MDBNavbarBrand>
                            <NavLink to="/"><img src={require('../../assets/img/logos/navLogo.png')} alt="logo" className="nav-logo pointer" onClick={() => { this.scrollTo('top') }} /></NavLink>
                        </MDBNavbarBrand>
                        <div className="mdb-hamburger-menu-container hide-mobile-nav">
                            <a id="mdb-hamburger-menu" className="closed" href="#menu" onClick={(e) => this.toggleMenu(e)}>
                                <div className="hamburger-line top"></div>
                                <div className="hamburger-line middle"></div>
                                <div className="hamburger-line bottom"></div>
                            </a>
                        </div>
                        <MDBCollapse id="navbarCollapse" isOpen={this.state.menuOpen} className={this.state.menuOpen ? 'fade-in' : null} navbar>
                            <MDBNavbarNav right>
                                {links}
                            </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBContainer>
                </MDBNavbar>
            </>
        );
    }
}

export default withRouter(Nav);