import React from 'react';
import Nav from './Components/Nav/Nav'
import './App.css';
import Home from './Components/Home/HomePage'
import Contact from './Components/Contact/ContactPage'
import AboutUs from './Components/About/AboutUs'
import Services from './Components/Services/Services'
import ClientCare from './Components/ClientCare/ClientCare'
import Footer from './Components/Footer/Footer'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './assets/css/style.css'


export function scrollTo(id) {
  var testDiv = document.getElementById(id);
  window.scrollTo({
    top: (testDiv.offsetTop - 50),
    behavior: 'smooth',
  });
}

function App() {

  // useEffect(() => {
  //   let ele = document.getElementById('init-loader');
  //   if (ele) {
  //     window.setTimeout(() => {
  //       ele.classList.add('loading-container-fade');
  //       window.setTimeout(() => {
  //         ele.classList.add('hide-loader');
  //       }, 550);
  //     }, 2000);
  //   }
  // })

  return (
    <div className="App">
      <Router>
        <Nav />
        <Switch>
          <Route path="/contact-us">
            <Contact />
          </Route>
          <Route path="/legal-services">
            <Services />
          </Route>
          <Route path="/about-us">
            <AboutUs />
          </Route>
          <Route path="/client-care">
            <ClientCare />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
