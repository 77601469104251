import React from 'react'
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from 'mdbreact'
import './style.css'

class FooterPage extends React.Component {
  authCtx = null

  navigate = (url) => {
    // navigate to top of page
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    })
  }

  toggleAuthModal = (show, page) => {
    this.authCtx.toggleShow(show, page)
  }

  logout = () => {
    this.authCtx.logout()
  }

  render() {
    this.authCtx = this.context.auth

    return (
      <MDBFooter color='secondary-color' className='font-small font-quatBold'>
        <div style={{ backgroundColor: '#b7b9bb' }}>
          <MDBContainer className='text-center text-md-left pt-4 pb-4'>
            {/* <MDBRow className="py-4 d-flex align-items-center">
              <MDBCol md="6" lg="5" className="text-center text-md-left mb-4 mb-md-0">
                <h6 className="mb-0 font-weight-md secondary-text">
                  Get connected with us on our social networks.
              </h6>
              </MDBCol>
              <MDBCol md="6" lg="7" className="text-center text-md-right">
                <a className="li-ic ml-0" href="https://" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-linkedin secondary-text mr-4 mr-lg-4"> </i>
                </a>
                <a className="ins-ic" href="https://" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-instagram secondary-text mr-4 mr-lg-4"> </i>
                </a>
                <a className="fb-ic ml-0" href="https://" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-facebook-f secondary-text mr-4 mr-lg-4"> </i>
                </a>
              </MDBCol>
            </MDBRow> */}
          </MDBContainer>
        </div>
        <MDBContainer className='text-center text-md-left footer-padding-top-bottom font-quatRegular'>
          <MDBRow className='mt-3'>
            <MDBCol md='6' lg='6' className='mb-4 m-lr-auto'>
              <h6 className='text-uppercase font-quatBold mb-4'>
                <strong>Contact Us</strong>
              </h6>
              <MDBRow>
                <MDBCol md='2' lg='2' className='mb-0 m-lr-auto'>
                  <p>
                    <b className='font-quatBold'>Phone</b>
                  </p>
                </MDBCol>
                <MDBCol md='10' lg='10' className='mb-0 m-lr-auto'>
                  <p>
                    <a className='white-text' href='tel:+6433778909'>
                      03 377 8909
                    </a>
                  </p>
                </MDBCol>
                <MDBCol md='2' lg='2' className='mb-0 m-lr-auto'>
                  <p>
                    <b className='font-quatBold'>Fax</b>
                  </p>
                </MDBCol>
                <MDBCol md='10' lg='10' className='mb-0 m-lr-auto'>
                  <p>
                    <a className='white-text' href='tel:+6433778908'>
                      03 377 8908
                    </a>
                  </p>
                </MDBCol>
                <MDBCol md='2' lg='2' className='mb-0 m-lr-auto'>
                  <p>
                    <b className='font-quatBold'>Email</b>
                  </p>
                </MDBCol>
                <MDBCol md='10' lg='10' className='mb-0 m-lr-auto'>
                  <p>
                    <a className='white-text' href='mailto:firm@rolton-macduff.nz'>
                      firm@rolton-macduff.nz
                    </a>
                  </p>
                </MDBCol>
                <MDBCol md='2' lg='2' className='mb-0 m-lr-auto'>
                  <p>
                    <b className='font-quatBold'>Office</b>
                  </p>
                </MDBCol>
                <MDBCol md='10' lg='10' className='mb-0 m-lr-auto'>
                  <p>47 Mandeville Street, Riccarton, Christchurch</p>
                </MDBCol>
                <MDBCol md='2' lg='2' className='mb-0 m-lr-auto'>
                  <p>
                    <b className='font-quatBold'>Postal</b>
                  </p>
                </MDBCol>
                <MDBCol md='10' lg='10' className='mb-0 m-lr-auto mb-5'>
                  <p>PO Box 25285, Christchurch 8140</p>
                </MDBCol>
              </MDBRow>
            </MDBCol>

            <MDBCol md='6' lg='6' className='mb-4 m-lr-auto'>
              <h6 className='text-uppercase font-quatBold mb-4'>
                <strong>Rolton MacDuff Lawyers</strong>
              </h6>
              <p>
                Rolton MacDuff Lawyers, is a progressive, client focused, property and business Law Firm. Based in Christchurch, we service
                clients throughout New Zealand.
                <br />
                <br />
                All Rights Reserved.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className='footer-copyright footer-padding font-text text-center'>
          <MDBContainer fluid>
            <br />
            <div className='white-text text-uppercase'>&copy; {new Date().getFullYear()} Copyright Rolton MacDuff Lawyers</div>
            <p className='text-uppercase mb-2 grey-text'>
              <a href='http://xodesoftware.com/' rel='noopener noreferrer' alt='Designed by xodesoftware.com' target='_blank'>
                Designed by Xode
              </a>
            </p>
          </MDBContainer>
        </div>
      </MDBFooter>
    )
  }
}
export default FooterPage
