import React from "react";
import { withRouter } from 'react-router-dom';
import ScrollToTop from '../Nav/ScrollToTop'
import { scrollTo } from '../../App';
import { Helmet } from 'react-helmet'
import { Parallax } from 'react-parallax'
import {
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBContainer,
    MDBIcon,
    MDBAnimation
} from "mdbreact";
import "./style.css";

class Home extends React.Component {
    state = {
        collapsed: false
    };

    handleTogglerClick = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    };

    componentDidMount() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    scrollToGetStarted() {
        var testDiv = document.getElementById("get-started");
        window.scroll({
            top: (testDiv.offsetTop - 25),
            behavior: 'smooth',
        });
    }

    render() {

        return (
            <div id="apppage" style={{ overflow: "hidden" }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Rolton MacDuff Lawyers | Property and Business Law</title>
                    {/* <description>We offer a full range of property, business and personal legal services to a diverse client base.</description> */}
                    <link rel="canonical" href="https://www.rolton-macduff.nz" />
                </Helmet>
                <ScrollToTop />
                <Parallax
                    bgImage={require('../../assets/img/homeHeader1.jpg')}
                    bgImageAlt="Christchurch City"
                    strength={500}
                    className="page-header header-filter clear-filter dark-filter white-text"
                    style={{ borderBottom: '22px solid #01427e' }}
                >
                    <MDBContainer className="text-left mt-5">
                        <div style={{ zIndex: '2', position: "relative" }}>
                            <MDBAnimation reveal type="slideInDown" className="fast">
                                <h1 className="display-h1 text-uppercase font-quatRegular mt-5">Property and business law</h1>
                            </MDBAnimation>
                            <h6 className="h5-responsive mt-3 mb-lg-4 font-quatRegular text-justify">We offer property, business and personal legal services to a diverse client base throughout New Zealand.</h6>
                            <MDBBtn color="secondary" onClick={() => this.props.history.push('/contact-us')}>Contact Us</MDBBtn>
                            <MDBBtn outline color="white" onClick={() => scrollTo('get-started')}>Learn More</MDBBtn>
                        </div>
                    </MDBContainer>

                    <div style={{ height: '90vh' }} />
                </Parallax>

                <MDBContainer className="my-5 text-left pt-5 pb-5 text-left" id="get-started">
                    <MDBRow>
                        <MDBCol md="4" className="display-center mb-5">
                            <h1 className="font-quatBold display-h3 secondary-text">Progressive, client focused, property and business Law Firm. </h1>
                            <hr />
                        </MDBCol>
                        <MDBCol md="8">
                            <MDBAnimation reveal type="slideInRight" className="fast">
                                <h2 className="font-quatRegular h2-responsive secondary-text mt-3">About Us</h2>
                                <div className="h6-responsive mt-4 mb-2 default-text text-justify">We pride ourselves on our attention to detail and ability to relate to our clients and their legal needs. We are results driven and have a genuine interest in our clients, their family and business interests.
                            <br /><br />Based in Christchurch, we service clients throughout New Zealand. Our partners and staff have extensive experience and provide superior service to meet the legal needs of our clients. Offering a range of property, business and personal legal services to a diverse client base, we operate from modern, centrally located offices where parking is readily available.
                                </div>
                                <div className="hover-text h6-responsive text-left" onClick={() => this.props.history.push('/about-us')}>Learn more about us <i className="fas fa-angle-right pt-2 ml-2"></i></div>
                            </MDBAnimation>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <Parallax
                    bgImage={require('../../assets/img/family.png')}
                    bgImageAlt="family"
                    strength={200}
                    className="page-header header-filter clear-filter dark-filter white-text">
                    <MDBContainer className="text-left">
                        <div style={{ zIndex: '2', position: "relative" }}>
                            <MDBCol md="7">
                                <h3 className="h2-responsive font-quatRegular">You need reliable legal advisors you can relate to, understand and trust.</h3>
                                <MDBBtn outline color="white" onClick={() => this.props.history.push('/contact-us')}>Book an appointment</MDBBtn>
                            </MDBCol>
                        </div>
                    </MDBContainer>
                    <div style={{ height: '350px' }} />
                </Parallax>

                <MDBContainer className="my-5 text-left pt-5 pb-5 text-center" id="law-services">
                    <MDBAnimation reveal type="fadeIn" className="slow">
                        <MDBIcon icon="landmark" className="grey-text" size="3x" />
                        <h2 className="h1-responsive font-quatRegular mt-4 secondary-text">Our Services</h2>
                    </MDBAnimation>
                    <div className="h4-responsive mt-4 mb-5 default-text">We offer a range of legal services to clients throughout New Zealand in property, business and personal law.</div>
                    <MDBRow className="mb-5 text-left display-center pl-lg-5">
                        <MDBCol md="6" className="mx-auto pl-lg-5">
                            <div className="ml-3 h4-responsive hover-text-bold mt-2 mb-2"><MDBIcon icon="chevron-right" className="mr-3" />Buying and Selling Property</div>
                            <div className="ml-3 h4-responsive hover-text-bold mt-2 mb-2"><MDBIcon icon="chevron-right" className="mr-3" />Buying and Selling Business</div>
                            <div className="ml-3 h4-responsive hover-text-bold mt-2 mb-2"><MDBIcon icon="chevron-right" className="mr-3" />Mortgages and Refinancing</div>
                            <div className="ml-3 h4-responsive hover-text-bold mt-2 mb-2"><MDBIcon icon="chevron-right" className="mr-3" />Business and Commercial Matters</div>
                        </MDBCol>
                        <MDBCol md="6" className="mx-auto pl-lg-5">
                            <div className="ml-3 h4-responsive hover-text-bold mt-2 mb-2"><MDBIcon icon="chevron-right" className="mr-3" />Relationship Property and Separations</div>
                            <div className="ml-3 h4-responsive hover-text-bold mt-2 mb-2"><MDBIcon icon="chevron-right" className="mr-3" />Enduring Powers of Attorney</div>
                            <div className="ml-3 h4-responsive hover-text-bold mt-2 mb-2"><MDBIcon icon="chevron-right" className="mr-3" />Estate Administration</div>
                            <div className="ml-3 h4-responsive hover-text-bold mt-2 mb-2"><MDBIcon icon="chevron-right" className="mr-3" />Wills</div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <div className="default-bg pt-5 pb-5">
                    <MDBContainer className="text-left pt-5 pb-4">
                        <div style={{ zIndex: '2', position: "relative" }}>
                            <MDBCol md="9">
                                <h3 className="h3-responsive font-quatRegular mb-3 text-justify">Whatever your next legal move, you will benefit from our unique approach to the delivery of legal services.</h3>
                                <MDBBtn outline color="white" onClick={() => this.props.history.push('/contact-us')}>Contact Us</MDBBtn>
                            </MDBCol>
                        </div>
                    </MDBContainer>
                </div>

            </div >
        );
    }
}

export default withRouter(Home);