import React from 'react'
import { withRouter } from 'react-router-dom'
import ScrollToTop from '../Nav/ScrollToTop'
import { scrollTo } from '../../App'
import { Helmet } from 'react-helmet'
import { Parallax } from 'react-parallax'
import { MDBRow, MDBCol, MDBBtn, MDBContainer, MDBIcon, MDBAnimation } from 'mdbreact'
import AboutModal from './AboutModal'
// import "./style.css";

class AboutUs extends React.Component {
  state = {
    collapsed: false
  }

  handleTogglerClick = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  componentDidMount() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  scrollToGetStarted() {
    var testDiv = document.getElementById('get-started')
    window.scroll({
      top: testDiv.offsetTop - 25,
      behavior: 'smooth'
    })
  }

  render() {
    return (
      <div id='about' style={{ overflow: 'hidden' }}>
        <Helmet>
          <meta charSet='utf-8' />
          <title>About Us | Rolton MacDuff Lawyers | Property and Business Law</title>
          {/* <description>We offer a full range of property, business and personal legal services to a diverse client base.</description> */}
          <link rel='canonical' href='https://www.rolton-macduff.nz/about-us' />
        </Helmet>
        <ScrollToTop />
        <Parallax
          bgImage={require('../../assets/img/office.png')}
          bgImageAlt='Christchurch City'
          // strength={200}
          className='page-header header-filter clear-filter dark-filter white-text'
          style={{ borderBottom: '22px solid #01427e' }}
        >
          <MDBContainer className='text-left mt-5'>
            <div style={{ zIndex: '2', position: 'relative' }}>
              <MDBAnimation reveal type='slideInDown' className='fast'>
                <h1 className='h6-responsive text-uppercase light-grey-text font-quatRegular mt-5'>About Us</h1>
              </MDBAnimation>
              <h6 className='h3-responsive mt-3 mb-lg-4 font-quatRegular'>
                We are a progressive, client focused, property and business Law Firm.
              </h6>
            </div>
          </MDBContainer>

          <div style={{ height: '70vh' }} />
        </Parallax>

        <MDBContainer className='my-5 text-left pt-5 pb-5 text-left' id='get-started'>
          <div className='h5-responsive mt-4 mb-4 secondary-text text-justify'>
            <b className='font-weight-md'>
              We pride ourselves on our attention to detail and ability to relate to our clients and their legal needs. We are results
              driven and have a genuine interest in our clients, their family and business interests.
            </b>
            <br />
            <br />
            Based in Christchurch, we service clients throughout New Zealand. Our partners and staff have extensive experience and provide
            superior service to meet the legal needs of our clients. Offering a range of property, business and personal legal services to a
            diverse client base, we operate from modern, centrally located offices where parking is readily available.
          </div>
          <MDBBtn color='secondary' outline onClick={() => scrollTo('our-team')}>
            Meet our team
          </MDBBtn>
        </MDBContainer>

        <Parallax
          bgImage={require('../../assets/img/business-people1.png')}
          bgImageAlt='family'
          strength={200}
          className='page-header header-filter clear-filter dark-filter white-text'
        >
          <MDBContainer className='text-left'>
            <div style={{ zIndex: '2', position: 'relative' }}>
              <MDBCol md='9' className='mx-auto'>
                <h3 className='h2-responsive text-center font-quatRegular'>
                  We pride ourselves on our attention to detail and ability to relate to our clients and their legal needs.
                </h3>
              </MDBCol>
            </div>
          </MDBContainer>
          <div style={{ height: '300px' }} />
        </Parallax>

        <MDBContainer className='my-5 text-left pt-5 pb-5 text-center' id='our-team'>
          <MDBAnimation reveal type='slideInDown' className='slow'>
            <h2 className='display-h3 font-quatRegular mt-5 mb-3 secondary-text'>Our Team</h2>
          </MDBAnimation>
          <MDBRow className='grey-text'>
            <MDBCol md='6' className='mx-auto mt-5'>
              <AboutModal
                image={require('../../assets/img/team/amber.png')}
                description={
                  'Amber advises clients on a broad range of property and commercial matters including residential and commercial conveyancing, subdivisions, business acquisitions and sales, commercial leases, relationship property, wills, enduring powers of attorney and estate administration. Amber prides herself on good communication with her clients and takes a practical and friendly approach.<br /><br /> She enjoys helping first home buyers into their new home and navigating the often-perceived daunting experience of buying a first home.<br /><br /> In her spare time Amber enjoys spending time with her husband, and young son; and health and fitness'
                }
                name={'Amber James'}
                title={'Partner'}
                email={'amber@rolton-macduff.nz'}
                phone={'03 377 8909'}
                mobile={'022 152 6198'}
              />
              <h3 className='mt-4 mb-2 secondary-text font-quatRegular'>Amber James</h3>
              <h5 className='mb-2 text-uppercase font-weight-md'>Partner</h5>
            </MDBCol>
            <MDBCol md='6' className='mx-auto mt-5'>
              <AboutModal
                image={require('../../assets/img/team/bede.png')}
                description={
                  'Bede’s main areas of practice have a strong property and business focus including residential and commercial conveyancing, subdivisions, business purchases and sales, commercial leases, relationship property, wills, estate planning and estate administration.<br /><br /> Bede is the convenor of a New Zealand Law Society, Lawyers Standards Committee.<br /><br /> In his spare time, Bede enjoys spending time with family and friends, relaxing listening to some music, mountain biking, and his interests in motorcycles, contemporary art, photography, and property related matters.'
                }
                name={'Bede Rolton'}
                title={'Partner'}
                email={'bdr@rolton-macduff.nz'}
                linkedIn={'https://www.linkedin.com/in/bede-rolton-96962a117/'}
                phone={'03 377 8909'}
                mobile={'027 435 7760'}
              />
              <h3 className='mt-4 mb-2 secondary-text font-quatRegular'>Bede Rolton</h3>
              <h5 className='mb-2 text-uppercase font-weight-md'>Partner</h5>
              <a href='https://www.linkedin.com/in/bede-rolton-96962a117/' target='_blank' rel='noopener noreferrer' alt='LinkedIn'>
                <MDBIcon fab icon='linkedin' className='secondary-text mb-2 ml-2' size='1x' />
              </a>
            </MDBCol>
            {/* <MDBCol md="4" className="mx-auto mt-5">
                            <AboutModal
                                image={require('../../assets/img/team/alison.png')}
                                description={'Alison retired from the partnership on 31 March 2020.<br /><br />Alison’s main areas of practice have been in residential and rural conveyancing, business purchases and sales, enduring powers of attorney, wills and estate administration. <br /><br />In retirement, Alison is looking forward to more time to pursue her interests outside of law and to spend with family. <br /><br />Alison’s wealth of knowledge and experience remains available to the firm on a consulting basis.'}
                                name={'Alison MacDuff'}
                                title={'Consultant'}
                                email={'akm@rolton-macduff.nz'}
                                phone={'03 377 8909'}
                                // mobile={'027 487 3787'}
                            />
                            <h3 className="mt-4 mb-2 secondary-text font-quatRegular">Alison MacDuff</h3>
                            <h5 className="mb-5 text-uppercase font-weight-md">Consultant</h5>
                        </MDBCol> */}
            <MDBCol md='4' className='mx-auto mt-5'>
              <AboutModal
                image={require('../../assets/img/team/tamara.png')}
                description={
                  'Tamara is a Legal Executive and works closely with the partners on all client matters.<br /><br /> Tamara joined Rolton MacDuff in April 2020 and holds a Diploma in Legal Executive Studies.<br /><br /> In her spare time, Tamara is a dance instructor and enjoys spending time with her friends and family.                                '
                }
                name={'Tamara Pope'}
                title={'Legal Executive'}
                email={'tamara@rolton-macduff.nz'}
                phone={'03 377 8909'}
              />
              <h3 className='mt-4 mb-2 secondary-text font-quatRegular'>Tamara Pope</h3>
              <h5 className='mb-5 text-uppercase font-weight-md'>Legal Executive</h5>
            </MDBCol>
            <MDBCol md='4' className='mx-auto mt-5'>
              <AboutModal
                image={require('../../assets/img/team/helen.png')}
                description={
                  'Helen joined Rolton MacDuff in February 2019 and assists in the operation of our Trust Account.<br /><br /> Helen has been involved with legal trust accounting for many years including as Trust Account Administrator, providing nationwide software support for a Trust Accounting Package, and in part time and relieving Trust Accounting roles for several Christchurch firms.<br /><br /> In her spare time, Helen is an active member of committees of the Canterbury Folk Art Guild and The Down by the Liffey Gallery in Lincoln. She enjoys drawing and other craft activities.<br /><br /> Helen loves spending time with her family and friends, travel and reading.'
                }
                name={'Helen Seach'}
                title={'Trust Administrator'}
                email={'firm@rolton-macduff.nz'}
                phone={'03 377 8909'}
              />
              <h3 className='mt-4 mb-2 secondary-text font-quatRegular'>Helen Seach</h3>
              <h5 className='mb-5 text-uppercase font-weight-md'>Trust Administrator</h5>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        <div className='default-bg pt-5 pb-5'>
          <MDBContainer className='text-left pt-5 pb-5'>
            <MDBCol md='12' className='mx-auto'>
              <div className='font-quatBold h1-responsive mt-3 mb-5 white-text text-center'>Why choose us – Our Philosophy</div>
              <MDBRow>
                <MDBCol md='6' className='mx-auto'>
                  <div className='white-text h6-responsive font-quatBold mb-5 text-justify'>
                    From your first introduction to us, it will be apparent that we strive to be different. We pride ourselves on our
                    attention to detail; ability to relate to you and your legal needs; ability to deliver consistent results and genuine
                    interest in you, your family and business.
                    <br />
                    <br />
                    First and foremost, the practise of law is a business. No different in most respects to your business or the business in
                    which you work. The most important person to any business is you – the customer or in our case our client. Our client
                    philosophy is simple – to treat you as we would want to be treated! Our philosophy is deeper than just good service. Our
                    vision for the future demanded a departure from the traditionally held concepts of legal practice.
                  </div>
                </MDBCol>
                <MDBCol md='6' className='mx-auto'>
                  <div className='white-text h6-responsive font-quatBold mb-5 text-justify'>
                    Our Firm is built on the solid foundations of our reputation for getting results. Life and business today, and in the
                    future, will bring many new challenges for you. There will be increasing demands on your time, more complex decision
                    making and greater responsibility and pressure on you. You will need reliable legal advisors who you can relate to,
                    understand and trust. Whatever your next legal move, you will benefit from our unique approach to the delivery of legal
                    services.
                    <br />
                    <br />
                    Our uncompromising dedication to the highest standards of client service, business acumen and ability to deliver
                    friendly, timely, cost-effective advice will give you the confidence to plan your future knowing you are well
                    represented.
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBContainer>
        </div>
      </div>
    )
  }
}

export default withRouter(AboutUs)
