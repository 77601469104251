import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import ReactHtmlParser from 'react-html-parser';


class ModalPage extends Component {
    state = {
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {


        return (
            <MDBContainer>
                <div onClick={this.toggle} style={{ cursor: 'pointer' }}>
                    <img src={this.props.image} alt="our team" className="rounded-img" />
                </div>
                <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
                    <MDBModalHeader toggle={this.toggle} className="text-left">
                        <div className="secondary-text font-quatRegular h4-responsive pb-0">{this.props.name}</div>
                        {this.props.title}
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol md="8" className="pr-lg-4">
                                <div className="mb-5 text-justify">
                                    {ReactHtmlParser(`${this.props.description}`)}
                                </div>
                            </MDBCol>
                            <MDBCol md="4" className="mx-auto pr-lg-4">
                                <img src={this.props.image} alt="our team" className="rounded-img w-100 mb-5" />
                            </MDBCol>
                            <MDBCol md="2" lg="2" className="text-left">
                                <p>
                                    <b className="font-quatRegular">Email</b>
                                </p>
                            </MDBCol>
                            <MDBCol md="10" lg="10" className="text-left">
                                <p>
                                    <a className="secondary-text" href={`mailto:${this.props.email}`}>{this.props.email}</a>
                                </p>
                            </MDBCol>
                            <MDBCol md="2" lg="2" className="text-left">
                                <p>
                                    <b className="font-quatRegular">Phone</b>
                                </p>
                            </MDBCol>
                            <MDBCol md="10" lg="10" className="text-left">
                                <p>
                                    <a className="secondary-text" href={`tel:+64${this.props.phone}`}>{this.props.phone}</a>
                                </p>
                            </MDBCol>
                            {
                                this.props.mobile ?

                                    <>
                                        <MDBCol md="2" lg="2" className="text-left">
                                            <p>
                                                <b className="font-quatRegular">Mobile</b>
                                            </p>
                                        </MDBCol>
                                        <MDBCol md="10" lg="10" className="text-left mb-3">
                                            <a className="secondary-text" href={`tel:+64${this.props.mobile}`}>{this.props.mobile}</a>
                                        </MDBCol>
                                    </>

                                    : null
                            }
                            {
                                this.props.linkedIn ?

                                    <>
                                        <MDBCol md="2" lg="2" className="text-left">
                                            <p>
                                                <b className="font-quatRegular">LinkedIn</b>
                                            </p>
                                        </MDBCol>
                                        <MDBCol md="10" lg="10" className="text-left">
                                            <a href={this.props.linkedIn} target="_blank" rel="noopener noreferrer" alt="LinkedIn"><MDBIcon fab icon="linkedin" className="secondary-text" size="1x" /></a>
                                        </MDBCol>
                                    </>

                                    : null
                            }
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer >
        );
    }
}

export default ModalPage;