import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBBtn } from 'mdbreact'
import ScrollToTop from '../Nav/ScrollToTop'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { Parallax } from 'react-parallax'
import './style.css'
import { axRequest, METHOD_POST } from '../../utils/request'
import { SK, SUCCESS_COLOR, FAILED_COLOR } from '../../utils/constants'

class ContactUsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      sendMessage: '',
      color: '',
      data: {
        key: SK,
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        extra: ''
      }
    }
  }

  submitHandler = async (event) => {
    event.preventDefault()
    event.target.className += ' was-validated'
    let inputs = event.nativeEvent.target
    for (let i = 0; i < inputs.length; i++) {
      let input = inputs[i]

      if (input.name === 'extra') continue

      if (input != null && !input.validity.valid) {
        return
      }
    }

    let s = this.state
    s.processing = true
    s.sendMessage = ''
    this.setState(s)

    let response = await axRequest(null, '/client-mailers', METHOD_POST, this.state.data)
    s = this.state
    if (response && response.success) {
      s.color = SUCCESS_COLOR
      s.sendMessage = 'Thank you for your message, we will be in touch soon.'
      this.resetForm()
    } else {
      s.color = FAILED_COLOR
      s.sendMessage = 'Oops, something went wrong, please try again!'
    }
    s.processing = false
    this.setState(s)
  }

  handleChange = (event) => {
    let s = this.state
    s.data[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    this.setState(s)
  }

  resetForm = () => {
    document.getElementById('contact-form').classList.remove('was-validated')
    let s = this.state
    s.data = {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    }

    this.setState(s)
  }

  render() {
    return (
      <div className='mx-auto'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Contact Us | Rolton MacDuff Lawyers | Property and Business Law</title>
          {/* <description>We offer a full range of property, business and personal legal services to a diverse client base.</description> */}
          <link rel='canonical' href='https://www.rolton-macduff.nz/contact-us' />
        </Helmet>
        <ScrollToTop />
        <Parallax
          bgImage={require('../../assets/img/contact.png')}
          bgImageAlt='Contact'
          // strength={200}
          className='page-header header-filter clear-filter dark-filter white-text'
          style={{ borderBottom: '22px solid #01427e' }}
        >
          <MDBContainer className='text-left mt-5'>
            <div style={{ zIndex: '2', position: 'relative' }}>
              <MDBAnimation reveal type='slideInDown' className='fast'>
                <h1 className='h6-responsive text-uppercase light-grey-text font-quatRegular mt-5'>Contact Us</h1>
              </MDBAnimation>
              <h6 className='h3-responsive mt-3 mb-lg-4 font-quatRegular'>
                We would love to hear from you. Call us on{' '}
                <a className='white-text' href='tel:+6433778909'>
                  03 377 8909
                </a>
                .
              </h6>
            </div>
          </MDBContainer>

          <div style={{ height: '70vh' }} />
        </Parallax>
        <div id='contact' className='pt-5 pb-5'>
          <MDBContainer className='mt-5 divider-margin'>
            <MDBRow className='secondary-text mb-5'>
              <MDBCol md='12' lg='6' className='text-left font-quatBold secondary-text'>
                <h5 className='h4-responsive mb-4 mt-3 font-quatBold'>Contact Details</h5>
                <MDBRow>
                  <MDBCol md='2' lg='2' className='mb-0 m-lr-auto'>
                    <p>
                      <b className='font-quatBold'>Phone</b>
                    </p>
                  </MDBCol>
                  <MDBCol md='10' lg='10' className='mb-0 m-lr-auto'>
                    <p>
                      <a className='secondary-text' href='tel:+6433778909'>
                        03 377 8909
                      </a>
                    </p>
                  </MDBCol>
                  <MDBCol md='2' lg='2' className='mb-0 m-lr-auto'>
                    <p>
                      <b className='font-quatBold'>Fax</b>
                    </p>
                  </MDBCol>
                  <MDBCol md='10' lg='10' className='mb-0 m-lr-auto'>
                    <p>
                      <a className='secondary-text' href='tel:+6433778908'>
                        03 377 8908
                      </a>
                    </p>
                  </MDBCol>
                  <MDBCol md='2' lg='2' className='mb-0 m-lr-auto'>
                    <p>
                      <b className='font-quatBold'>Email</b>
                    </p>
                  </MDBCol>
                  <MDBCol md='10' lg='10' className='mb-0 m-lr-auto'>
                    <p>
                      <a className='secondary-text' href='mailto:firm@rolton-macduff.nz'>
                        firm@rolton-macduff.nz
                      </a>
                    </p>
                  </MDBCol>
                  <MDBCol md='2' lg='2' className='mb-0 m-lr-auto'>
                    <p>
                      <b className='font-quatBold'>Office</b>
                    </p>
                  </MDBCol>
                  <MDBCol md='10' lg='10' className='mb-0 m-lr-auto'>
                    <p>47 Mandeville Street, Riccarton, Christchurch</p>
                  </MDBCol>
                  <MDBCol md='2' lg='2' className='mb-0 m-lr-auto'>
                    <p>
                      <b className='font-quatBold'>Postal</b>
                    </p>
                  </MDBCol>
                  <MDBCol md='10' lg='10' className='mb-0 m-lr-auto mb-5'>
                    <p>PO Box 25285, Christchurch 8140</p>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol md='12' lg='6'>
                <div className='mapouter mx-auto map'>
                  <div className='gmap_canvas'>
                    <iframe
                      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2892.46709866947!2d172.6049442788124!3d-43.53430427228727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318a603ebf70b1%3A0x924f617da47b15b5!2s47%20Mandeville%20Street%2C%20Riccarton%2C%20Christchurch%208011!5e0!3m2!1sen!2snz!4v1597037716098!5m2!1sen!2snz'
                      style={{ width: '100%', height: '100%', frameborder: '0', border: '0' }}
                      allowfullscreen=''
                      aria-hidden='false'
                      tabindex='0'
                    ></iframe>
                  </div>
                </div>
              </MDBCol>
              <MDBCol md='12' lg='12' className='text-left mx-auto secondary-text mb-5 mt-5'>
                <div className='text-left h4-responsive mx-auto mt-2 mb-4 font-quatBold'>Send us a message</div>
                <form id='contact-form' className='needs-validation' onSubmit={this.submitHandler} noValidate>
                  {this.state.sendMessage && this.state.sendMessage.length > 0 ? (
                    <MDBRow>
                      <MDBCol style={{ minHeight: '45px', padding: '5px 0px 25px 0px', fontSize: '14px', textAlign: 'center' }}>
                        <MDBAnimation type='bounceIn' duration='500ms'>
                          <div
                            className='font-quatRegular'
                            style={{ padding: '15px 5px 0px 5px', fontWeight: 500, fontSize: '20px', color: this.state.color }}
                          >
                            {this.state.sendMessage}
                          </div>
                        </MDBAnimation>
                      </MDBCol>
                    </MDBRow>
                  ) : null}
                  <div className='form-group'>
                    <label htmlFor='name' className='grey-text'>
                      Your name
                    </label>
                    <input
                      type='text'
                      id='name'
                      name='name'
                      className='form-control'
                      value={this.state.data.name}
                      onChange={this.handleChange}
                      disabled={this.state.processing}
                      required
                    />
                    <div className='invalid-feedback'>Required.</div>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label htmlFor='email' className='grey-text'>
                      Your email
                    </label>
                    <input
                      type='email'
                      id='email'
                      name='email'
                      className='form-control'
                      value={this.state.data.email}
                      onChange={this.handleChange}
                      disabled={this.state.processing}
                      required
                    />
                    <div className='invalid-feedback'>Required.</div>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label htmlFor='phone' className='grey-text'>
                      Your phone number
                    </label>
                    <input
                      type='tel'
                      id='phone'
                      name='phone'
                      pattern='^(0\d\d{7}|02\d\d{6,12}|0800\d{5,12})$'
                      className='form-control'
                      value={this.state.data.phone}
                      onChange={this.handleChange}
                      disabled={this.state.processing}
                      required
                    />
                    <div className='invalid-feedback'>Required.</div>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label htmlFor='subject' className='grey-text'>
                      Subject
                    </label>
                    <input
                      type='text'
                      id='subject'
                      name='subject'
                      className='form-control'
                      value={this.state.data.subject}
                      onChange={this.handleChange}
                      disabled={this.state.processing}
                      required
                    />
                    <div className='invalid-feedback'>Required.</div>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label htmlFor='message' className='grey-text'>
                      Your message
                    </label>
                    <textarea
                      type='text'
                      id='message'
                      name='message'
                      className='form-control'
                      rows='3'
                      value={this.state.data.message}
                      onChange={this.handleChange}
                      disabled={this.state.processing}
                      required
                    />
                    <div className='invalid-feedback'>Required.</div>
                  </div>
                  <div className='form-group' style={{ display: 'none' }}>
                    <label htmlFor='message' className='grey-text'>
                      Extra Info
                    </label>
                    <textarea
                      type='text'
                      id='extra'
                      name='extra'
                      className='form-control'
                      rows='3'
                      value={this.state.data.extra}
                      onChange={this.handleChange}
                      disabled={this.state.processing}
                      required
                    />
                    <div className='invalid-feedback'>Required.</div>
                  </div>
                  <div className='text-center mt-4'>
                    <MDBBtn
                      color='secondary'
                      type='submit'
                      style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}
                      disabled={this.state.processing}
                    >
                      Send message
                      {!this.state.processing ? null : (
                        <div className='spinner-border' style={{ width: '25px', height: '25px', marginLeft: '10px' }} role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                      )}
                    </MDBBtn>
                  </div>
                </form>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
    )
  }
}

export default withRouter(ContactUsPage)
