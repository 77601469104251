import React from "react";
import { withRouter } from 'react-router-dom';
import ScrollToTop from '../Nav/ScrollToTop'
// import { scrollTo } from '../../App';
import { Helmet } from 'react-helmet'
import { Parallax } from 'react-parallax'
import {
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBContainer,
    MDBIcon,
    MDBAnimation
} from "mdbreact";
// import "./style.css";

class Services extends React.Component {
    state = {
        collapsed: false
    };

    handleTogglerClick = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    };

    componentDidMount() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    scrollToGetStarted() {
        var testDiv = document.getElementById("get-started");
        window.scroll({
            top: (testDiv.offsetTop - 25),
            behavior: 'smooth',
        });
    }

    render() {
        return (
            <div id="apppage" style={{ overflow: "hidden" }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Legal Services | Rolton MacDuff Lawyers | Property and Business Law</title>
                    {/* <description>We offer a full range of property, business and personal legal services to a diverse client base.</description> */}
                    <link rel="canonical" href="https://www.rolton-macduff.nz/legal-services" />
                </Helmet>
                <ScrollToTop />
                <Parallax
                    bgImage={require('../../assets/img/services.png')}
                    bgImageAlt="Christchurch City"
                    // strength={200}
                    className="page-header header-filter clear-filter dark-filter white-text"
                    style={{ borderBottom: '22px solid #01427e' }}
                >
                    <MDBContainer className="text-left mt-5">
                        <div style={{ zIndex: '2', position: "relative" }}>
                            <MDBAnimation reveal type="slideInDown" className="fast">
                                <h1 className="h6-responsive text-uppercase light-grey-text font-quatRegular mt-5">Our services</h1>
                            </MDBAnimation>
                            <h6 className="h3-responsive mt-3 mb-lg-4 font-quatRegular">Our partners and staff have extensive experience and provide superior service.</h6>
                        </div>
                    </MDBContainer>
                    <div style={{ height: '70vh' }} />
                </Parallax>

                <MDBContainer className="my-5 text-center pt-5 pb-5 text-left" id="law-services">
                    <MDBRow className="mb-5 text-left">
                        <MDBCol md="12" className="mx-auto pb-5">
                            <div className="font-quatBold h1-responsive mt-4 mb-4 secondary-text text-center">How we can help you</div>
                        </MDBCol>
                        <MDBCol md="4" className="mx-auto mb-5">
                            <div className="text-center">
                                <img src={require('../../assets/img/property.png')} alt="property" className="rounded-img" />
                                <div className="font-quatBold h1-responsive mt-4 mb-4 secondary-text text-center">Property</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Buying and Selling property</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">KiwiSaver First Home Withdrawals</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Mortgages and Refinancing</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Occupation Right Agreements</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Reverse Mortgages</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Building Contract Review</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Subdivisions</div>
                            </div>
                        </MDBCol>
                        <MDBCol md="4" className="mx-auto mb-5">
                            <div className="text-center">
                                <img src={require('../../assets/img/commercial.png')} alt="commercial" className="rounded-img" />
                                <div className="font-quatBold h1-responsive mt-4 mb-4 secondary-text text-center">Business</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Business and Commercial Matters</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Buying and Selling Business</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Commercial Leases</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Shareholder Agreements</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Partnership Agreements</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Bank Guarantees</div>
                            </div>
                        </MDBCol>
                        <MDBCol md="4" className="mx-auto mb-5">
                            <div className="text-center">
                                <img src={require('../../assets/img/personal.png')} alt="personal" className="rounded-img" />
                                <div className="font-quatBold h1-responsive mt-4 mb-4 secondary-text text-center">Personal</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Wills</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Enduring Powers of Attorney</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Estate Administration</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Relationship Property and Separations</div>
                                <div className="h5-responsive hover-text-bold mt-2 mb-2">Contracting Out Agreements</div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <div className="default-bg pt-5 pb-5">
                    <MDBContainer className="text-left pt-5 pb-4">
                        <div style={{ zIndex: '2', position: "relative" }}>
                            <MDBCol md="9">
                                <h3 className="h3-responsive font-quatRegular mb-3">Whatever your next legal move, you will benefit from our unique approach to the delivery of legal services.</h3>
                                <MDBBtn outline color="white" onClick={() => this.props.history.push('/contact-us')}>Contact Us</MDBBtn>
                            </MDBCol>
                        </div>
                    </MDBContainer>
                </div>
            </div >
        );
    }
}

export default withRouter(Services);